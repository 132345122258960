import React, { Fragment } from "react";
import { Menu, MenuItem, Button } from "@material-ui/core";
import { GetApp } from "@material-ui/icons";

export default function MenuExport({
  handleOption1,
  handleOption2,
  handleOption3,
  handleOption4,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handle = (handleOption) => {
    handleOption();
    setAnchorEl(null);
  };

  const isMenuOpen = Boolean(anchorEl);
  const menuId = "export";

  return (
    <Fragment>
      <Button
        onClick={handleProfileMenuOpen}
        color="primary"
        aria-controls={menuId}
        startIcon={<GetApp />}
      >
        Xuất danh sách
      </Button>

      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        id={menuId}
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={() => handle(handleOption1)}>DS thường</MenuItem>
        <MenuItem onClick={() => handle(handleOption2)}>
          DS đề nghị khen thưởng
        </MenuItem>
        <MenuItem onClick={() => handle(handleOption3)}>
          DS xếp loại ABC
        </MenuItem>
        <MenuItem onClick={() => handle(handleOption4)}>DS tổng hợp</MenuItem>
      </Menu>
    </Fragment>
  );
}
