import React, { Fragment } from "react";
import { isLoaded, isEmpty, useFirestoreConnect } from "react-redux-firebase";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  TableRow,
  TableHead,
  TableCell,
  Table,
  TableBody,
  Grid,
} from "@material-ui/core";
import moment from "moment";
import Search from "./Search";
import StaffForm from "./StaffForm";
import { fullName } from "../utils/staffUtils";
import { selectStaff } from "../store/reducers/currentStaff";
import { styles } from "../constants/styles";

const useStyles = makeStyles(styles);

const StaffList = () => {
  const [searchKey, setSearchKey] = React.useState(null);
  const classes = useStyles();
  const onSearch = (key) => setSearchKey(key.toLowerCase());

  const [state, setState] = React.useState({
    VC: true,
    HD: true,
    HT: false,
    EX: false,
    includesKeyword: true,
    selectedStatuses: ["VC", "HD", "TN_T", "TN_DT"],
  });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  const handleStatusFilterChange = (selectedItems) => {
    setState({ ...state, selectedStatuses: selectedItems });
  };

  useFirestoreConnect([{ collection: "staffs" }]);
  const staffs = useSelector((state) => state.firestore.ordered.staffs);

  const dispatch = useDispatch();

  const table = (staffs) => {
    return (
      <Table stickyHeader size="small" id="sheetjs">
        <TableHead>
          <TableRow>
            <TableCell>HHHV</TableCell>
            <TableCell>Họ và tên</TableCell>
            <TableCell>Bộ môn/Phòng thí nghiệm</TableCell>
            <TableCell>Chức vụ</TableCell>
            <TableCell>Từ ngày</TableCell>
            <TableCell>Tới ngày</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {staffs.map(({ position, fromDate, toDate, staff }, index) => (
            <TableRow hover key={index}>
              <TableCell>
                {!staff.rank ? staff.degree : staff.rank + "." + staff.degree}
              </TableCell>
              <TableCell onClick={() => dispatch(selectStaff(staff))}>
                {fullName(staff)}
              </TableCell>
              <TableCell>{staff.department}</TableCell>

              <TableCell>{position}</TableCell>
              <TableCell>{fromDate}</TableCell>
              <TableCell>{toDate}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  };

  const theme = useTheme();
  const { primary } = theme.palette;
  const currentStaff = useSelector((state) => state.currentStaff.data);
  return (
    <div>
      {!isLoaded(staffs) ? (
        "Đang tải..."
      ) : isEmpty(staffs) ? (
        "Danh sách rỗng"
      ) : (
        <Fragment>
          {/* <div style={{ color: primary.dark, backgroundColor: primary[50] }}>
            <Grid
              container
              spacing={3}
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item>
                <Filter
                  selectedItems={state.selectedStatuses}
                  handleChange={handleStatusFilterChange}
                />
              </Grid>

              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.includesKeyword}
                      onChange={handleChange}
                      name="includesKeyword"
                      color="primary"
                    />
                  }
                  label="có chứng chỉ"
                />
              </Grid>
              <Grid item>
                <Search onSearch={onSearch} placeholder="từ khóa chứng chỉ" />
              </Grid>
            </Grid>
          </div> */}

          <Grid container spacing={1}>
            <Grid item xs={12}>
              <div className={classes.table_wrapper}>
                {table(listByOfficePositions(staffs))}
              </div>
            </Grid>

            {currentStaff && (
              <StaffForm readOnly={false} validateName={validateName} />
            )}
          </Grid>
        </Fragment>
      )}
    </div>
  );
};

const listByOfficePositions = (staffs) => {
  const officePositions = [];
  const today = new Date();
  for (const staff of staffs) {
    const { positions } = staff;
    if (positions) {
      for (const pos of positions) {
        const { value, fromDate, toDate } = pos;
        if (
          toDate === "undefined" ||
          !toDate ||
          moment(toDate).isAfter(today)
        ) {
          officePositions.push({
            position: value,
            fromDate: fromDate,
            toDate: toDate,
            staff: staff,
          });
        }
      }
    }
  }
  officePositions.sort((a, b) => byToDateEarliestFirst(a, b));
  return officePositions;
};

const byToDateEarliestFirst = (a, b) => {
  if (!a.toDate) return -1;
  if (!b.toDate) return 1;
  if (a.toDate === b.toDate) return 0;
  return moment(a.toDate).isAfter(moment(b.toDate)) ? 1 : -1;
};

const validateName = (id, name) => null;

export default StaffList;
