import React from 'react'
import {
  Route,
  Redirect
} from 'react-router-dom';
import { useSelector } from 'react-redux'
import { isLoaded, isEmpty } from 'react-redux-firebase'

import { SIGN_IN } from '../../constants/routes';
import Loading from '../Loading';

function PrivateRoute({ children, ...rest }) {
  const auth = useSelector(state => state.firebase.auth)
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoaded(auth) ? (
          !isEmpty(auth) ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: SIGN_IN,
                state: { from: location }
              }}
            />
          )
        ) : (
          <Loading/>
        )        
      }
    />
  );
}

function WithAuthentication({ children, ...rest }) {
  const auth = useSelector(state => state.firebase.auth)
  return (
    
        isLoaded(auth) && !isEmpty(auth) ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: SIGN_IN,
            }}
          />
        )
  );
}

function AuthIsLoaded({ children }) {
  const auth = useSelector(state => state.firebase.auth)
  if (!isLoaded(auth)) return <div>loading ...</div>;
  return children
}

export { PrivateRoute, AuthIsLoaded, WithAuthentication }