import React, { Fragment } from "react";
import {
  TableRow,
  TableHead,
  TableCell,
  Table,
  TableBody,
  Divider,
  Dialog,
  IconButton,
  TextField,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Typography,
} from "@material-ui/core";
import { Edit } from "@material-ui/icons";

const toSchoolYear = (year) => (year - 1).toString().concat("-").concat(year);
const toAwardList = ({ awards, note }) => awards.join(", ");
const toNote = ({ awards, note }) => note;

const TDKT = ({ tdkt, onChange }) => {
  const lines = Object.entries(tdkt).filter(
    (line) => line.length > 1 && line[1]
  );

  const [editLine, setEditLine] = React.useState(null);

  const handleClickOpen = (event, line) => {
    const newLine = {
      year: line[0],
      awards: toAwardList(line[1]),
      note: toNote(line[1]),
    };
    setEditLine(newLine);
  };

  const handleClose = () => {
    const { year, awards, note } = editLine;
    let newTdkt = { ...tdkt };
    newTdkt[year] = {
      note: note,
      awards: awards.replace(/\s*,\s*/g, ",").split(","),
    };
    console.log(newTdkt);
    onChange(newTdkt);
    setEditLine(null);
  };

  const handleCancel = () => {
    setEditLine(null);
  };

  const handleChange = (title) => ({ target }) => {
    let newLine = { ...editLine };
    newLine[title] = target.value;
    setEditLine(newLine);
  };

  return (
    <Fragment>
      <Divider />
      <Grid spacing={3} container direction="column">
        <Grid item>
          <Typography>Thi đua khen thưởng</Typography>
        </Grid>

        <Grid item>
          {lines.length > 0 && (
            <Table stickyHeader size="small" id="sheetjs">
              <TableHead>
                <TableRow>
                  <TableCell>Năm học</TableCell>
                  <TableCell>Danh hiệu</TableCell>
                  <TableCell>Thành tích</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {lines.map((line, index) => (
                  <TableRow key={index}>
                    <TableCell>{toSchoolYear(line[0])}</TableCell>
                    <TableCell>{toAwardList(line[1])}</TableCell>
                    <TableCell>{toNote(line[1])}</TableCell>
                    <TableCell>
                      <IconButton
                        size="small"
                        onClick={(event) => handleClickOpen(event, line)}
                      >
                        <Edit color="primary" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </Grid>
      </Grid>

      {editLine && (
        <Dialog open onClose={handleClose}>
          <DialogTitle>TĐKT năm học {toSchoolYear(editLine.year)}</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              value={editLine.awards}
              onChange={handleChange("awards")}
              label="Danh hiệu thi đua khen thưởng"
              fullWidth
              variant="standard"
            />
            <TextField
              label="Thành tích"
              value={editLine.note}
              margin="dense"
              onChange={handleChange("note")}
              fullWidth
              variant="standard"
              multiline
              rowsMax="5"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel}>Hủy</Button>
            <Button onClick={handleClose}>Ghi nhận</Button>
          </DialogActions>
        </Dialog>
      )}
    </Fragment>
  );
};

export default TDKT;
