import React from "react";

import {
  TextField,
  MenuItem,
  Paper,
  IconButton,
  Typography,
  Grid,
  Checkbox,
  ListItemText,
} from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { useFirestore } from "react-redux-firebase";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import AutoCompleteText from "../AutoCompleteText";
import {
  STAFF_TYPES,
  SEXES,
  DEGREES,
  RANKS,
  DEPARTMENTS,
  STATUS_MAP,
} from "../../constants/defaultValues";
import { Save, Undo, Close } from "@material-ui/icons";
import moment from "moment";

import History from "./History";
import DeleteButton from "./DeleteButton";
import Tdkt from "./Tdkt";
import ABC from "./ABC";
import {
  editStaff,
  unSelectStaff,
  selectStaff,
  cancelEditStaff,
} from "../../store/reducers/currentStaff";
import {
  getMultiCurrent,
  getCurrent,
  getLatest,
  displayCertificates,
} from "../../utils/staffUtils";
import { styles, DATE_FORMAT } from "../../constants/styles";

import {
  SALARY_HISTORY_TABLE,
  LEAVE_HISTORY_TABLE,
  TRAVEL_HISTORY_TABLE,
  POSITION_HISTORY_TABLE,
  CONTRACT_HISTORY_TABLE,
  CERTIFICATE_HISTORY_TABLE,
} from "../../constants/staffPageTables";

const useStyles = makeStyles(styles);

const FormBase = ({ readOnly, validateName }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const currentStaff = useSelector((state) => state.currentStaff.data);
  const dirty = useSelector((state) => state.currentStaff.dirty);
  const firestore = useFirestore();
  const [expanded, setExpanded] = React.useState(null);
  const profile = useSelector((state) => state.firebase.profile);
  const { permissions } = profile;
  const canEdit = permissions.includes("staff:edit");
  console.log(permissions);
  console.log(canEdit);

  const handleToggleExpanded = (key) => {
    setExpanded(key === expanded ? null : key);
  };

  const updateState = (key, value) => {
    dispatch(editStaff({ key, value }));
  };
  const handleChange = (title) => ({ target }) =>
    updateState(title, target.value);

  const handleSubmit = () => {
    const { id, name } = currentStaff;
    const splitIndex = name.lastIndexOf(" ");
    const toPersist = {
      ...currentStaff,
      firstName: name.substring(splitIndex + 1),
      lastName: name.substring(0, splitIndex),
    };
    delete toPersist.name;

    if (id) {
      console.log(toPersist);
      firestore.update({ collection: "staffs", doc: id }, toPersist);
      dispatch(selectStaff(toPersist));
    } else {
      firestore.add("staffs", toPersist);
      dispatch(unSelectStaff());
    }
  };

  const handleDelete = () => {
    const { id } = currentStaff;
    if (id) {
      firestore.delete({ collection: "staffs", doc: id });
      dispatch(unSelectStaff());
    }
  };

  const inputProps = { readOnly: readOnly };
  const SelectField = (field, label, value, options) => (
    <TextField
      select
      label={label}
      value={value}
      onChange={handleChange(field)}
      InputProps={inputProps}
      fullWidth
      SelectProps={{
        MenuProps: {
          className: classes.menu,
        },
      }}
      className={classes.textField}
      margin="normal"
      variant="outlined"
    >
      {options.map(({ value, label }) => (
        <MenuItem key={value} value={value}>
          {label}
        </MenuItem>
      ))}
    </TextField>
  );

  const SelectFieldMap = (field, label, value, optionMap) => (
    <TextField
      select
      label={label}
      value={value}
      onChange={handleChange(field)}
      InputProps={inputProps}
      fullWidth
      SelectProps={{
        MenuProps: {
          className: classes.menu,
        },
      }}
      className={classes.textField}
      margin="normal"
      variant="outlined"
    >
      {Object.entries(STATUS_MAP).map(([value, label]) => (
        <MenuItem key={value} value={value}>
          {label}
        </MenuItem>
      ))}
    </TextField>
  );

  const SelectMultiFieldMap = (field, label, selectedItems, optionMap) => (
    <TextField
      select
      label={label}
      InputProps={inputProps}
      fullWidth
      SelectProps={{
        multiple: true,
        value: selectedItems || [],
        renderValue: (selected) =>
          selected.map((item) => optionMap[item]).join(", "),
        onChange: handleChange(field),
        MenuProps: {
          className: classes.menu,
        },
      }}
      className={classes.textField}
      margin="normal"
      variant="outlined"
    >
      {Object.entries(optionMap).map(([key, value]) => (
        <MenuItem key={key} value={key}>
          <Checkbox
            checked={selectedItems && selectedItems.indexOf(key) > -1}
          />
          <ListItemText primary={value} />
        </MenuItem>
      ))}
    </TextField>
  );

  const SimpleTextField = (field, label, value, errorMessage) => (
    <TextField
      id={field}
      error={!!errorMessage}
      helperText={errorMessage}
      label={label}
      value={value ? value : ""}
      onChange={handleChange(field)}
      InputProps={inputProps}
      fullWidth
      variant="outlined"
      size="medium"
    />
  );

  const {
    id,
    yearOfBirth,
    sex,
    type,
    degree,
    rank,
    positions,
    contracts,
    name,
    email,
    phone,
    status,
    startDate,
    salaries,
    leaves,
    businessTrips,
    department,
    notes,
    bank,
    accountNumber,
    TIN,
    tdkt,
    abc,
    certificates,
  } = currentStaff;
  console.log(canEdit);
  return (
    <Paper className={classes.staffForm}>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Grid item>
          <Typography variant="h5">{name}</Typography>
        </Grid>
        <Grid item>
          <div>
            {canEdit && (
              <IconButton
                onClick={handleSubmit}
                className="button"
                disabled={!name || !dirty}
              >
                <Save color={!dirty || !name ? "disabled" : "primary"} />
              </IconButton>
            )}
            {!!id && canEdit && (
              <IconButton
                onClick={() => dispatch(cancelEditStaff())}
                className="button"
                disabled={!dirty}
              >
                <Undo color={!!dirty ? "primary" : "disabled"} />
              </IconButton>
            )}
            {!!id && canEdit && <DeleteButton onConfirm={handleDelete} />}
            <IconButton
              onClick={() => dispatch(unSelectStaff())}
              className="button"
            >
              <Close color="primary" />
            </IconButton>
          </div>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} xl={3}>
          {SimpleTextField("name", "Họ và tên", name, validateName(id, name))}
        </Grid>

        <Grid item xs={6} sm={3}>
          {SimpleTextField("yearOfBirth", "Năm sinh", yearOfBirth)}
        </Grid>

        <Grid item xs={6} sm={3}>
          {SelectField("sex", "Giới tính", sex, SEXES)}
        </Grid>

        <Grid item xs={6} sm={6} lg={3}>
          {SelectField("rank", "Học hàm", rank ? rank : "", RANKS)}
        </Grid>

        <Grid item xs={6} sm={6} lg={3}>
          {SelectField("degree", "Học vị", degree, DEGREES)}
        </Grid>

        <Grid item xs={12} sm={6} xl={3}>
          {SelectField("type", "Ngạch", type, STAFF_TYPES)}
        </Grid>

        <Grid item xs={12} sm={6} xl={3}>
          <AutoCompleteText
            label="Bộ môn/Phòng"
            value={department}
            onChange={(value) => updateState("department", value)}
            items={DEPARTMENTS}
            readOnly={readOnly}
          />
        </Grid>

        <Grid item xs={12} sm={3}>
          {SimpleTextField("email", "Email", email)}
        </Grid>

        <Grid item xs={12} sm={3}>
          {SimpleTextField("phone", "Phone", phone)}
        </Grid>

        <Grid item xs={12} sm={6} xl={3}>
          {SelectMultiFieldMap("status", "Phân loại", status, STATUS_MAP)}
        </Grid>

        <Grid item xs={6} sm={3}>
          <KeyboardDatePicker
            autoOk
            variant="inline"
            inputVariant="outlined"
            label="Ngày bắt đầu"
            format={DATE_FORMAT}
            placeholder={DATE_FORMAT}
            value={startDate ? startDate : ""}
            InputAdornmentProps={{ position: "start" }}
            onChange={(m) => {
              updateState("startDate", !!m ? m.format("YYYY-MM-DD") : null);
            }}
            invalidDateMessage={"Định dạng " + DATE_FORMAT}
          />
        </Grid>

        <Grid item xs={6} sm={3}>
          <TextField
            id="years"
            label="Thâm niên"
            value={calculateDuration(startDate)}
            readOnly
            InputProps={inputProps}
            fullWidth
            variant="outlined"
            size="medium"
          />
        </Grid>

        <Grid item xs={12} xl={6}>
          <TextField
            label="Chú thích"
            className={classes.textField}
            value={notes}
            onChange={handleChange("notes")}
            margin="normal"
            InputProps={inputProps}
            fullWidth
            variant="outlined"
            multiline
            rowsMax="2"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          {SimpleTextField("bank", "Ngân hàng", bank)}
        </Grid>
        <Grid item xs={12} sm={3}>
          {SimpleTextField("accountNumber", "Số tài khoản", accountNumber)}
        </Grid>
        <Grid item xs={12} sm={3}>
          {SimpleTextField("TIN", "MST", TIN)}
        </Grid>
      </Grid>

      <Grid container style={{ gap: 25 }}>
        <Grid item xs={12}>
          <History
            title="Chứng chỉ:"
            summary={displayCertificates(certificates)}
            items={certificates}
            expanded={expanded === "certificates"}
            handleToggleExpanded={() => handleToggleExpanded("certificates")}
            onChange={(value) => updateState("certificates", value)}
            columns={CERTIFICATE_HISTORY_TABLE}
          />
        </Grid>

        <Grid item xs={12}>
          <History
            title="Hợp đồng:"
            summary={getCurrent(contracts)}
            items={contracts}
            expanded={expanded === "contracts"}
            handleToggleExpanded={() => handleToggleExpanded("contracts")}
            onChange={(value) => updateState("contracts", value)}
            columns={CONTRACT_HISTORY_TABLE}
          />
        </Grid>

        <Grid item xs={12}>
          <History
            title="Hệ số lương:"
            summary={displaySalary(getLatest(salaries))}
            items={salaries}
            onChange={(value) => updateState("salaries", value)}
            columns={SALARY_HISTORY_TABLE}
            expanded={expanded === "salaries"}
            handleToggleExpanded={() => handleToggleExpanded("salaries")}
          />
        </Grid>

        <Grid item xs={12}>
          <History
            title={"Chức vụ: "}
            summary={getMultiCurrent(positions)}
            items={positions}
            onChange={(value) => updateState("positions", value)}
            columns={POSITION_HISTORY_TABLE}
            expanded={expanded === "positions"}
            handleToggleExpanded={() => handleToggleExpanded("positions")}
          />
        </Grid>

        <Grid item xs={12}>
          <History
            title="Nghỉ chế độ:"
            summary={getCurrent(leaves)}
            items={leaves}
            onChange={(value) => updateState("leaves", value)}
            columns={LEAVE_HISTORY_TABLE}
            expanded={expanded === "leaves"}
            handleToggleExpanded={() => handleToggleExpanded("leaves")}
          />
        </Grid>

        <Grid item xs={12}>
          <History
            title="Công tác:"
            summary={getCurrent(businessTrips)}
            items={businessTrips}
            onChange={(value) => updateState("businessTrips", value)}
            columns={TRAVEL_HISTORY_TABLE}
            expanded={expanded === "businessTrips"}
            handleToggleExpanded={() => handleToggleExpanded("businessTrips")}
          />
        </Grid>

        <Grid item xs={12}>
          {tdkt && (
            <Tdkt
              tdkt={tdkt}
              onChange={(value) => updateState("tdkt", value)}
            />
          )}
        </Grid>

        <Grid item xs={12}>
          {abc && (
            <ABC abc={abc} onChange={(value) => updateState("abc", value)} />
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};

const calculateDuration = (startDate) => {
  if (!startDate) return "";

  const startMoment = moment(startDate);
  const diff = startMoment.diff(moment(new Date()));
  const years = moment.duration(diff).years();
  return (years < 0 ? -years : 0) + " năm";
};

const displaySalary = (salary) => {
  if (!salary) return "";
  const { fromDate, value, type } = salary;
  return (
    value + " từ " + moment(fromDate).format(DATE_FORMAT) + " (" + type + ")"
  );
};

export default FormBase;
