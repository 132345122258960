export const styles = (theme) => ({
  staffForm: {
    margin: theme.spacing(1),
    height: "100%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      width: "65%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "65%",
    },
    overflow: "auto",
    padding: theme.spacing(3),
    position: "fixed",
    top: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 20,
  },
  paper: {
    margin: theme.spacing(1),
    width: "100%",
    overflow: "auto",
    padding: theme.spacing(3),
  },
  table_wrapper: {
    /*maxHeight: 700, */
    overflow: "auto",
    whiteSpace: "nowrap",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    margin: theme.spacing(0),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  list: {
    width: "100%",
  },
  button_small: {
    display: "inline-block",
    padding: 0,
    minHeight: 0,
    minWidth: 0,
  },
  section1: {
    padding: theme.spacing(5),
  },
});

export const DATE_FORMAT = "DD-MM-YYYY";
