import React, { Fragment } from "react";
import { isLoaded, isEmpty, useFirestoreConnect } from "react-redux-firebase";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  TableRow,
  TableHead,
  TableCell,
  Table,
  TableBody,
  Grid,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";

import Search from "./Search";
import StaffForm from "./StaffForm";
import {
  displayList,
  filter,
  staffCompare,
  fullName,
  displayCertificates,
  getAbc,
} from "../utils/staffUtils";
import { selectStaff } from "../store/reducers/currentStaff";
import { styles } from "../constants/styles";
import Filter from "./StaffListFilter";
import { STATUS_MAP } from "../constants/defaultValues";
import zIndex from "@material-ui/core/styles/zIndex";

const lastYear = new Date().getFullYear() - 1;

const useStyles = makeStyles(styles);

const StaffList = () => {
  const [searchKey, setSearchKey] = React.useState(null);
  const classes = useStyles();
  const onSearch = (key) => setSearchKey(key.toLowerCase());

  const [state, setState] = React.useState({
    VC: true,
    HD: true,
    HT: false,
    EX: false,
    includesKeyword: true,
    selectedStatuses: ["VC", "HD", "TN_T", "TN_DT"],
  });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  const handleStatusFilterChange = (selectedItems) => {
    setState({ ...state, selectedStatuses: selectedItems });
  };

  useFirestoreConnect([{ collection: "staffs" }]);
  const staffs = useSelector((state) => state.firestore.ordered.staffs);

  const dispatch = useDispatch();

  const table = (staffs) => {
    return (
      <Table stickyHeader size="small" id="sheetjs">
        <TableHead>
          <TableRow>
            <TableCell align="center">STT</TableCell>
            <TableCell>Họ và tên</TableCell>
            <TableCell>Bộ môn/Phòng thí nghiệm</TableCell>
            <TableCell align="center">HHHV</TableCell>
            <TableCell align="center">Phân loại</TableCell>
            <TableCell>
              ABC {lastYear - 4}-{lastYear}
            </TableCell>
            <TableCell>
              TDKT {lastYear - 4}-{lastYear - 3}
            </TableCell>
            <TableCell>
              {lastYear - 3}-{lastYear - 2}
            </TableCell>
            <TableCell>
              {lastYear - 2}-{lastYear - 1}
            </TableCell>
            <TableCell>
              {lastYear - 1}-{lastYear}
            </TableCell>
            <TableCell>
              {lastYear}-{lastYear + 1}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {staffs.map(({ staff, abc, tdkt }, index) => (
            <TableRow hover key={index}>
              <TableCell align="center">{index + 1}</TableCell>
              <TableCell onClick={() => dispatch(selectStaff(staff))}>
                {fullName(staff)}
              </TableCell>
              <TableCell>{staff.department}</TableCell>
              <TableCell align="center">
                {!staff.rank ? staff.degree : staff.rank + "." + staff.degree}
              </TableCell>
              <TableCell align="center">
                {displayList(staff.status, STATUS_MAP)}
              </TableCell>
              <TableCell>{abc}</TableCell>
              <TableCell>{tdkt[0]}</TableCell>
              <TableCell>{tdkt[1]}</TableCell>
              <TableCell>{tdkt[2]}</TableCell>
              <TableCell>{tdkt[3]}</TableCell>
              <TableCell>{tdkt[4]}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  };

  const theme = useTheme();
  const { primary } = theme.palette;
  const currentStaff = useSelector((state) => state.currentStaff.data);
  return (
    <div>
      {!isLoaded(staffs) ? (
        "Đang tải..."
      ) : isEmpty(staffs) ? (
        "Danh sách rỗng"
      ) : (
        <Fragment>
          <div style={{ color: primary.dark, backgroundColor: primary[50] }}>
            <Grid
              container
              spacing={3}
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item>
                <Filter
                  selectedItems={state.selectedStatuses}
                  handleChange={handleStatusFilterChange}
                />
              </Grid>

              <Grid item>
                <Search onSearch={onSearch} placeholder="từ khóa tìm kiếm" />
              </Grid>
            </Grid>
          </div>

          <Grid container spacing={1}>
            <Grid item xs={12}>
              <div className={classes.table_wrapper}>
                {table(
                  createFiveYearStatistics(filter(staffs, searchKey, state))
                )}
              </div>
            </Grid>

            {currentStaff && (
              <StaffForm readOnly={false} validateName={validateName} />
            )}
          </Grid>
        </Fragment>
      )}
    </div>
  );
};
const createFiveYearStatistics = (staffs) => {
  staffs.sort((a, b) => staffCompare(a, b));
  return staffs.map((staff) => ({
    staff: staff,
    abc: getAbcFiveYears(staff),
    tdkt: getTDKTFiveYears(staff),
  }));
};

const getAbcFiveYears = ({ abc }) => {
  const result = ["-", "-", "-", "-", "-"];
  if (abc) {
    Object.entries(abc)
      .filter((line) => line.length > 1 && line[1])
      .forEach((line) => {
        const index = 4 - (lastYear - Number(line[0]));
        if (index >= 0 && index < 5) result[index] = line[1].awards;
      });
  }
  return result.join(" ");
};

const getTDKTFiveYears = ({ tdkt }) => {
  const result = ["", "", "", "", ""];
  if (tdkt) {
    Object.entries(tdkt)
      .filter((line) => line.length > 1 && line[1])
      .forEach((line) => {
        const index = 4 - (lastYear - Number(line[0]));
        if (index >= 0 && index < 5) result[index] = line[1].awards.join(", ");
      });
  }
  return result;
};

const validateName = (id, name) => null;

export default StaffList;
