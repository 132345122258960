import './index.css';
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import { teal, amber } from '@material-ui/core/colors';
import App from './components/App';

const theme = createMuiTheme({
	palette: {
		primary: teal,
		secondary: amber
	}
})

ReactDOM.render(		
	<MuiThemeProvider theme={theme}>
		<App />
	</MuiThemeProvider>,
	document.getElementById('root'),
);
 
serviceWorker.unregister();
