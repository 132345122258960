import React, { Component } from "react";
import {
  TextField,
  ListItem,
  ListItemText,
  List,
  Paper,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import "./AutoCompleteText.css";

const styles = (theme) => ({
  paper: {
    maxHeight: 200,
    overflow: "auto",
    position: "absolute",
    width: "auto",
    display: "none",
    zIndex: 2,
  },
});

const isEmptyOrSpaces = (str) => !str || str.match(/^ *$/) !== null;

class AutoCompleteText extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
    };
  }

  onTextChanged = (e) => {
    const value = e.target.value;

    this.setState({
      show: true,
    });

    const { onChange } = this.props;
    onChange(value);
  };

  suggestionSelected(value) {
    this.setState({
      show: false,
    });

    const { onChange } = this.props;
    onChange(value);
  }

  renderSuggestions() {
    const { classes, items } = this.props;

    return items.length === 0 ? null : (
      <Paper className={classes.paper}>
        <List className={classes.list}>
          {items.map((item) => (
            <ListItem
              key={item}
              style={{ padding: "5px 10px 0px 10px", width: "100%" }}
              button
              onClick={() => this.suggestionSelected(item)}
            >
              <ListItemText primary={item} style={{ padding: 0 }} />
            </ListItem>
          ))}
        </List>
      </Paper>
    );
  }

  render() {
    const { show } = this.state;
    const { value, label, readOnly, classes } = this.props;

    return (
      <div className="wrapper">
        <TextField
          label={label}
          value={value}
          onChange={this.onTextChanged}
          margin="normal"
          InputProps={{ readOnly: readOnly }}
          fullWidth
          variant="outlined"
          className={classes.textField}
        />
        {!readOnly && (show || isEmptyOrSpaces(value)) && (
          <div tabIndex="-1">{this.renderSuggestions()}</div>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(AutoCompleteText);
