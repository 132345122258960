import { createSlice } from '@reduxjs/toolkit'

export const currentStaffSlice = createSlice({
  name: 'currentStaff',
  initialState: {data: null},
  reducers: {
    newStaff: (state) => {
      state.data = {sex: 'Nam', name: '', yearOfBirth: '', notes: '', department: ''};
      state.origin = null;
      state.dirty = true;
    },    
    selectStaff: (state, action) => {    
      const origin = action.payload;
      state.origin = origin;
      state.data = { ...origin, name: origin.lastName + ' ' + origin.firstName };
      state.dirty = false;
    },
    unSelectStaff: state => {
      state.data= null
      state.origin = null
    },
    editStaff: (state, action) => {      
        const {key, value} = action.payload;
        const old = {...state.data}
        old[key] = value;
        state.data = old;
        state.dirty = true;      
    }, 
    cancelEditStaff: (state) => {
      if (state.origin && state.dirty) {
        const origin = state.origin;
        state.data = { ...origin, name: origin.lastName + ' ' + origin.firstName };
        state.dirty = false;
      }
    }
  }
})

export const { newStaff, selectStaff, unSelectStaff, editStaff, cancelEditStaff } = currentStaffSlice.actions

export default currentStaffSlice.reducer