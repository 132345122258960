import React, { useState, Fragment } from "react";
import { Add, Undo, DeleteOutline, Done, Edit } from "@material-ui/icons";
import { KeyboardDatePicker } from "@material-ui/pickers";
import {
  IconButton,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  Select,
  MenuItem,
  TextField,
} from "@material-ui/core";
import Autocomplete from "@mui/material/Autocomplete";

import { makeStyles } from "@material-ui/core/styles";
import { DATE_FORMAT } from "../constants/styles";
import moment from "moment";

const useStyles = makeStyles((theme) => ({}));

const EditableTable = ({ columns, items, handleDataChange }) => {
  const classes = useStyles();
  const [isAdding, setIsAdding] = useState(false);
  const [isFormDataValid, setIsFormDataValid] = useState(false);

  const emptyFormData = () => {
    let data = {};
    for (var col in columns) {
      const { defaultValue, field } = col;
      if (defaultValue) data[field] = defaultValue;
    }
    return data;
  };

  const [editFormData, setEditFormData] = useState(emptyFormData());

  const [editItemId, setEditItemId] = useState(null);

  const requiredFields = columns
    .filter(({ required }) => required === true)
    .map(({ field }) => field);

  const validateFormData = (formData) => {
    for (var i in requiredFields) {
      const field = formData[requiredFields[i]];
      if (!field || field.trim() === "") return false;
    }
    return true;
  };

  const updateFormData = (newFormData) => {
    setEditFormData(newFormData);
    const valid = validateFormData(newFormData);
    setIsFormDataValid(valid);
  };

  const resetFormData = () => {
    setEditFormData(emptyFormData());
    setIsFormDataValid(false);
    setIsAdding(false);
    setEditItemId(null);
  };

  const handleEditFormChange = (event) => {
    const fieldName = event.target.name;
    const fieldValue = event.target.value;
    const newFormData = { ...editFormData };
    newFormData[fieldName] = fieldValue;
    updateFormData(newFormData);
  };

  const handleEditFreeSoloChange = (field, newValue) => {
    const newFormData = { ...editFormData };
    newFormData[field] = newValue;
    console.log(newValue);
    updateFormData(newFormData);
  };

  const handleEditDateChange = (title, moment) => {
    const fieldValue = !!moment ? moment.format("YYYY-MM-DD") : null;
    const newFormData = { ...editFormData };
    newFormData[title] = fieldValue;

    updateFormData(newFormData);
  };

  const handleAddSubmit = (event) => {
    var newItem = { id: items.length + 1 };
    for (var i in editFormData) newItem[i] = editFormData[i];
    const newItems = [newItem, ...items];

    resetFormData();
    handleDataChange(newItems);
  };

  const handleAddCancel = () => {
    resetFormData();
  };

  const handleEditFormSubmit = (event) => {
    var editedItem = { id: editItemId };
    for (var i in editFormData) editedItem[i] = editFormData[i];

    const newItems = [...items];
    const index = items.findIndex((item) => item.id === editItemId);
    newItems[index] = editedItem;

    resetFormData();
    handleDataChange(newItems);
  };

  const handleEditClick = (event, item) => {
    setEditItemId(item.id);

    var formValues = {};
    for (var i in item) formValues[i] = item[i];
    updateFormData(formValues);
  };

  const handleCancelClick = () => {
    resetFormData();
  };

  const handleAddClick = () => {
    setIsAdding(true);
    var formValues = {};
    for (var i in columns) {
      const { defaultValue, field } = columns[i];
      if (typeof defaultValue !== "undefined") {
        formValues[field] = defaultValue;
      }
    }
    updateFormData(formValues);
  };

  const handleDeleteClick = (itemId) => {
    const newItems = [...items];
    const index = items.findIndex((item) => item.id === itemId);
    newItems.splice(index, 1);
    handleDataChange(newItems);
  };

  const createView = (data, type, optionMap) => {
    switch (type) {
      case "date":
        return data && moment(data).format(DATE_FORMAT);
      case "select":
        return optionMap && data ? optionMap[data] : data;
      default:
        return data;
    }
  };

  const ReadOnlyRow = (item, allowsActions) => (
    <TableRow>
      {columns.map(
        ({ headerName, type, field, optionMap }, index) =>
          headerName && (
            <TableCell key={index}>
              {createView(item[field], type, optionMap)}
            </TableCell>
          )
      )}
      <TableCell>
        {allowsActions && (
          <Fragment>
            <IconButton
              size="small"
              onClick={(event) => handleEditClick(event, item)}
            >
              <Edit color="primary" />
            </IconButton>
            <IconButton size="small" onClick={() => handleDeleteClick(item.id)}>
              <DeleteOutline color="primary" />
            </IconButton>
          </Fragment>
        )}
      </TableCell>
    </TableRow>
  );

  const createEdit = ({ field, type, options, placeholder }) => {
    const value = editFormData[field];

    switch (type) {
      case "date":
        return (
          <KeyboardDatePicker
            autoOk
            name={field}
            sx={{ width: 50 }}
            variant="inline"
            placeholder={DATE_FORMAT}
            format={DATE_FORMAT}
            value={value}
            onChange={(moment) => handleEditDateChange(field, moment)}
            invalidDateMessage={"Định dạng " + DATE_FORMAT}
          />
        );
      case "select":
        return (
          <Select
            name={field}
            value={value}
            autoWidth
            onChange={handleEditFormChange}
            components={{
              IndicatorSeparator: () => null,
            }}
          >
            {options.map(({ value, label }, index) => (
              <MenuItem key={index} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
        );
      case "freeSolo":
        return (
          <Autocomplete
            id="free-solo-demo"
            freeSolo
            sx={{ width: 300 }}
            fullWidth
            options={options}
            value={value}
            onInputChange={(event, newValue) =>
              handleEditFreeSoloChange(field, newValue)
            }
            name={field}
            renderInput={(params) => <TextField size="medium" {...params} />}
          />
        );
      default:
        return (
          // <input
          //   type="text"
          //   required="required"
          //   placeholder={placeholder}
          //   name={field}
          //   value={value}
          //   onChange={handleEditFormChange}
          // ></input>*
          <TextField
            name={field}
            placeholder={placeholder}
            value={value ? value : ""}
            onChange={handleEditFormChange}
            fullWidth
            size="medium"
          />
        );
    }
  };

  const AddRow = () => (
    <TableRow>
      {columns.map(
        (col, index) =>
          col.headerName && <TableCell key={index}>{createEdit(col)}</TableCell>
      )}

      <TableCell>
        {isFormDataValid && (
          <IconButton size="small" onClick={handleAddSubmit}>
            <Done color="primary" />
          </IconButton>
        )}
        <IconButton size="small" onClick={handleAddCancel}>
          <Undo color="primary" />
        </IconButton>
      </TableCell>
    </TableRow>
  );

  const EditableRow = () => (
    <TableRow>
      {columns.map(
        (col, index) =>
          col.headerName && <TableCell key={index}>{createEdit(col)}</TableCell>
      )}

      <TableCell>
        {isFormDataValid && (
          <IconButton size="small" onClick={handleEditFormSubmit}>
            <Done color="primary" />
          </IconButton>
        )}
        <IconButton size="small" onClick={handleCancelClick}>
          <Undo color="primary" />
        </IconButton>
      </TableCell>
    </TableRow>
  );
  console.log(editFormData);

  return (
    <div className="app-container">
      <form onSubmit={handleEditFormSubmit}>
        <div className={classes.table_wrapper}>
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                {columns.map(
                  (col, index) =>
                    col.headerName && (
                      <TableCell key={index}>{col.headerName}</TableCell>
                    )
                )}
                <TableCell>
                  {!isAdding && !editItemId && (
                    <IconButton size="small" onClick={() => handleAddClick()}>
                      <Add color="primary" />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isAdding && AddRow()}
              {items.map((item, index) => (
                <Fragment key={index}>
                  {editItemId === item.id
                    ? EditableRow()
                    : ReadOnlyRow(item, !isAdding && !editItemId)}
                </Fragment>
              ))}
            </TableBody>
          </Table>
        </div>
      </form>
    </div>
  );
};

export default EditableTable;
