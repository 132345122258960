import React, { Fragment, Component } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { DeleteForever }  from '@material-ui/icons';

class AlertDialog extends Component {
  constructor(props) {
    super(props)
    
    this.state = {
      open: false,
    }
  }

  handleClickOpen = () => {
    this.setState ({ 
      open: true, 
    })
  }

  handleClose = () => {
    this.setState ({ 
      open: false, 
    })
  }

  handleConfirm = () => {    
    this.props.onConfirm()
    this.handleClose()
  }

  render() {
    return (
      <Fragment>      
        <IconButton className="button" onClick={this.handleClickOpen}>
          <DeleteForever color="error"/>
        </IconButton> 
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Xóa hồ sơ?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Dữ liệu bị xóa sẽ không thể khôi phục. Bạn có chắc chắn muốn xóa toàn bộ dữ liệu trong hồ sơ này?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary" autoFocus>
              Không
            </Button>
            <Button onClick={this.handleConfirm} color="primary">
              Có
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

export default AlertDialog