import React from "react";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";

import { STATUS_MAP } from "../constants/defaultValues";

const STATUS_FILTER_ALL = "ALL";
const STATUS_FILTER_MAP = {
  ALL: "Tất cả",
  ...STATUS_MAP,
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function filter({ selectedItems, handleChange }) {
  const onChange = (event) => {
    let displaySelectedItems = event.target.value;
    const allWasSelected = displaySelectedItems.indexOf(STATUS_FILTER_ALL) >= 0;

    const newSelectedItems = allWasSelected
      ? selectedItems.length === 0
        ? displaySelectedItems.filter((item) => item !== STATUS_FILTER_ALL)
        : []
      : displaySelectedItems;

    handleChange(newSelectedItems);
  };

  const displaySelectedItems =
    selectedItems.length > 0 ? selectedItems : [STATUS_FILTER_ALL];

  return (
    <Select
      labelId="demo-mutiple-checkbox-label"
      id="demo-mutiple-checkbox"
      multiple
      value={displaySelectedItems}
      onChange={onChange}
      input={<Input />}
      renderValue={(selected) =>
        selected.map((item) => STATUS_FILTER_MAP[item]).join(", ")
      }
      MenuProps={MenuProps}
    >
      {Object.entries(STATUS_FILTER_MAP).map(([key, value]) => (
        <MenuItem key={key} value={key}>
          <Checkbox checked={displaySelectedItems.indexOf(key) > -1} />
          <ListItemText primary={value} />
        </MenuItem>
      ))}
    </Select>
  );
}
