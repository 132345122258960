import React, { Fragment } from "react";
import {
  isLoaded,
  isEmpty,
  useFirestoreConnect,
  useFirestore,
} from "react-redux-firebase";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  TableRow,
  TableHead,
  TableCell,
  Table,
  TableBody,
  Grid,
  Button,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { AddCircle } from "@material-ui/icons";

import Search from "./Search";
import StaffForm from "./StaffForm";
import {
  displayList,
  getMultiCurrent,
  filter,
  staffCompare,
  fullName,
} from "../utils/staffUtils";
import { data } from "../utils/data";
import {
  exportTable,
  exportTDKT,
  exportABC,
  exportSummaryList,
} from "../utils/exportUtils";
import { newStaff, selectStaff } from "../store/reducers/currentStaff";
import { styles } from "../constants/styles";
import MenuExport from "./MenuExport";
import Filter from "./StaffListFilter";
import moment from "moment";
import { STATUS_MAP } from "../constants/defaultValues";

const useStyles = makeStyles(styles);

const StaffList = () => {
  const firestore = useFirestore();

  const [searchKey, setSearchKey] = React.useState(null);
  const classes = useStyles();
  const onSearch = (key) => setSearchKey(key.toLowerCase());

  const [state, setState] = React.useState({
    withPosition: false,
    selectedStatuses: ["VC", "HD", "TN_T", "TN_DT"],
  });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  const handleStatusFilterChange = (selectedItems) => {
    setState({ ...state, selectedStatuses: selectedItems });
  };

  useFirestoreConnect([{ collection: "staffs" }]);
  const staffs = useSelector((state) => state.firestore.ordered.staffs);

  const dispatch = useDispatch();

  const updateAllFromCollection = async (staffs) => {
    const batch = firestore.batch();

    staffs.forEach((staff) => {
      const name = fullName(staff);
      const dat = data.find((line) => line.name === name);
      if (dat) {
        const docRef = firestore.collection("staffs").doc(staff.id);
        batch.update(docRef, {
          startDate: moment(dat.start, "MM/dd/YYYY").format("YYYY-MM-DD"),
          email: dat.email,
        });
        console.log("Updating " + name);
      } else {
        console.log("not found" + name);
      }
    });
    await batch.commit();
    console.log("Committed ");
  };

  const table = (staffs) => {
    staffs.sort((a, b) => staffCompare(a, b));
    return (
      <Table stickyHeader size="small" id="sheetjs">
        <TableHead>
          <TableRow>
            <TableCell align="center">STT</TableCell>
            <TableCell>Họ và tên</TableCell>
            <TableCell>Bộ môn/Phòng thí nghiệm</TableCell>
            <TableCell align="center">HHHV</TableCell>
            <TableCell align="center">Phân loại</TableCell>
            <TableCell align="center">Email</TableCell>
            <TableCell>Chức vụ</TableCell>
            <TableCell>Chú thích</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {staffs.map((staff, index) => (
            <TableRow hover key={staff.id}>
              <TableCell align="center">{index + 1}</TableCell>
              <TableCell onClick={() => dispatch(selectStaff(staff))}>
                {staff.lastName} {staff.firstName}
              </TableCell>
              <TableCell>{staff.department}</TableCell>
              <TableCell align="center">
                {!staff.rank ? staff.degree : staff.rank + "." + staff.degree}
              </TableCell>
              <TableCell align="center">
                {displayList(staff.status, STATUS_MAP)}
              </TableCell>
              <TableCell>{staff.email}</TableCell>
              <TableCell>{getMultiCurrent(staff.positions)}</TableCell>
              <TableCell>{staff.notes}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  };

  const validateName = (id, name) => {
    const staff = staffs.find(
      (staff) => fullName(staff) === name && staff.id !== id
    );
    return staff
      ? "Trùng tên với " +
          fullName(staff) +
          (staff.department ? " (" + staff.department + ")" : "")
      : null;
  };

  const theme = useTheme();
  const { primary } = theme.palette;
  const currentStaff = useSelector((state) => state.currentStaff.data);

  return (
    <div>
      {!isLoaded(staffs) ? (
        "Đang tải..."
      ) : isEmpty(staffs) ? (
        "Danh sách rỗng"
      ) : (
        <Fragment>
          <div style={{ color: primary.dark, backgroundColor: primary[50] }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={3}>
                <Search onSearch={onSearch} placeholder="từ khóa tìm kiếm" />
              </Grid>

              <Grid item xs={12} md={4}>
                <Filter
                  selectedItems={state.selectedStatuses}
                  handleChange={handleStatusFilterChange}
                />
              </Grid>

              <Grid item xs={12} md={1}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.withPosition}
                      onChange={handleChange}
                      name="withPosition"
                      color="primary"
                    />
                  }
                  label="CBLĐ"
                />
              </Grid>

              <Grid item xs={12} md={2}>
                <Button
                  onClick={() => dispatch(newStaff())}
                  color="primary"
                  startIcon={<AddCircle />}
                >
                  Thêm cán bộ
                </Button>
              </Grid>

              <Grid item xs={12} md={2}>
                <MenuExport
                  handleOption1={() => exportTable("sheetjs")}
                  handleOption2={() =>
                    exportTDKT(filter(staffs, searchKey, state))
                  }
                  handleOption3={() =>
                    exportABC(filter(staffs, searchKey, state))
                  }
                  handleOption4={() =>
                    exportSummaryList(filter(staffs, searchKey, state))
                  }
                />
              </Grid>
            </Grid>
          </div>

          <Grid container spacing={1}>
            <Grid item xs={12}>
              {/*<Button
                className={classes.button_small}
                onClick={() => updateAllFromCollection(staffs)}
              >
                Update
              </Button> */}
              <div className={classes.table_wrapper}>
                {table(filter(staffs, searchKey, state))}
              </div>
            </Grid>

            {currentStaff && (
              <StaffForm readOnly={false} validateName={validateName} />
            )}
          </Grid>
        </Fragment>
      )}
    </div>
  );
};

export default StaffList;
