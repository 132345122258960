import React, { useState } from "react";
import History from "./StaffForm/History";

const EditTable = () => {
  const [data, setData] = useState([
    {
      fullName: "Jenny Chan",
      date: "2020-02-20",
      option: "A",
      freeSolo: "a",
    },
  ]);

  const [expanded, setExpanded] = useState(true);

  const handleToggleExpanded = (newData) => {
    setExpanded(!expanded);
  };

  const handleDataChange = (newData) => {
    setData(newData);
  };

  return (
    <div>
      <History
        title="Title"
        summary="Summary"
        columns={columns}
        expanded={expanded}
        handleToggleExpanded={handleToggleExpanded}
        items={data}
        onChange={handleDataChange}
      />
    </div>
  );
};

const columns = [
  {
    field: "fullName",
    headerName: "Full Name",
    width: 150,
    editable: true,
    required: true,
  },
  {
    field: "date",
    headerName: "Date",
    type: "date",
    width: 110,
    editable: true,
    required: true,
  },

  {
    field: "option",
    headerName: "Option",
    type: "select",
    width: 110,
    editable: true,
    required: true,
    optionMap: { A: "AAA", B: "BBB" },
    options: [
      { value: "A", label: "AAA" },
      { value: "B", label: "BBB" },
    ],
  },

  {
    field: "freeSolo",
    headerName: "FreeSolo",
    type: "freeSolo",
    width: 100,
    editable: true,
    required: true,
    options: ["Suggestion AAA", "Suggestion BBB"],
  },
];

export default EditTable;
