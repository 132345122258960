import * as XLSX from "xlsx";
import {
  getCurrent,
  fullName,
  getAbcByYearFromHistory,
  getTdktByYearFromHistory,
  calculateDuration,
} from "../utils/staffUtils";

const download = (url, name) => {
  let a = document.createElement("a");
  a.href = url;
  a.download = name;
  a.click();

  window.URL.revokeObjectURL(url);
};

function s2ab(s) {
  const buf = new ArrayBuffer(s.length);

  const view = new Uint8Array(buf);

  for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;

  return buf;
}

export const exportTable = (id) => {
  var tbl = document.getElementById(id);
  var wb = XLSX.utils.table_to_book(tbl);
  const wbout = XLSX.write(wb, {
    bookType: "xlsx",
    bookSST: true,
    type: "binary",
  });
  let url = window.URL.createObjectURL(
    new Blob([s2ab(wbout)], { type: "application/octet-stream" })
  );

  download(url, "DSCB.xlsx");
};

const fontName = "Calibri"; //'Times New Roman'
const headerBorder = {
  top: { style: "thin" },
  left: { style: "thin" },
  bottom: { style: "thin" },
  right: { style: "thin" },
};
const headerStyle = {
  font: { bold: true },
  alignment: { horizontal: "center", vertical: "center", wrapText: true },
};

export const exportTDKT = (staffs) => {
  var Excel = require("exceljs");
  var workbook = new Excel.Workbook();
  var ws = workbook.addWorksheet("TestData");

  ws.fontName = fontName;
  ws.getColumn("B").alignment = { horizontal: "center" };
  ws.mergeCells("B1:AB1");
  ws.getCell("B1").value = "Phụ lục 4b - Danh sách đề nghị khen thưởng cá nhân";
  ws.getCell("B1").style = {
    font: { bold: true, underline: true, name: fontName, size: 12 },
    alignment: { horizontal: "left" },
  };

  ws.mergeCells("B3:AB3");
  ws.getCell("B3").value = "DANH SÁCH ĐỀ NGHỊ KHEN THƯỞNG CÁ NHÂN";
  ws.getCell("B3").style = {
    font: { bold: true, name: fontName, size: 12 },
    alignment: { horizontal: "center" },
  };

  ws.mergeCells("B4:AB4");
  ws.getCell("B4").value =
    "(Kèm theo Tờ trình số                  ngày ... tháng ... năm 20...)";
  ws.getCell("B4").style = {
    font: { italic: true, name: fontName, size: 12 },
    alignment: { horizontal: "center" },
  };

  ws.getRow(5).values = [
    null,
    "STT",
    "Ông/Bà",
    null,
    "Học hàm, học vị",
    null,
    null,
    null,
    null,
    "Họ và tên",
    "Số tháng công tác trong năm",
    null,
    "Chức vụ",
    "Đơn vị",
    "Mức đề nghị Thi đua",
    null,
    null,
    null,
    "Mức đề nghị Khen thưởng",
    null,
    null,
    null,
    null,
    "Thành tích",
  ];
  const columns = [
    null,
    null,
    null,
    null,
    "GS.TS",
    "PSG.TS",
    "TS",
    "ThS",
    "CN",
    null,
    null,
    null,
    null,
    null,
    "LĐTT",
    "CSTĐ cấp CS",
    "CSTĐ cấp ĐHQGHN",
    "CSTĐ cấp BGD&ĐT",
    "Giấy khen HT",
    "Bằng khen GĐ ĐHQGHN",
    "Bằng khen Bộ GD&ĐT",
    "Bằng khen TTCP",
    "Huân chương LĐ",
    null,
  ];
  ws.getRow(6).values = columns;
  ws.mergeCells("B5:B6");
  ws.mergeCells("C5:D6");
  ws.mergeCells("E5:I5");
  ws.mergeCells("J5:J6");
  ws.mergeCells("K5:L6");
  ws.mergeCells("M5:M6");
  ws.mergeCells("N5:N6");
  ws.mergeCells("O5:R5");
  ws.mergeCells("S5:W5");
  ws.mergeCells("X5:X6");

  const cols = columns.map((title, i) => ({ name: i.toString() }));
  let sorted = staffs.slice();
  sorted.sort((s1, s2) => {
    if (s1.department > s2.department) return 1;
    if (s1.department < s2.department) return -1;
    return 0;
  });
  const rows = sorted.map(
    (
      { id, sex, degree, rank, positions, firstName, lastName, department },
      index
    ) => {
      let row = columns.map((title) => null);
      row[0] = id;
      row[1] = index + 1;
      row[9] = fullName({ firstName, lastName });
      row[12] = getCurrent(positions);
      row[13] = department;
      if (sex === "Nam") row[2] = "Ông";
      else row[3] = "Bà";
      if (rank === "GS") row[4] = "GS.TS.";
      else if (rank === "PGS") row[5] = "PGS.TS.";
      else if (degree === "TS") row[6] = "TS.";
      else if (degree === "ThS") row[7] = "ThS.";
      else row[8] = "CN";
      return row;
    }
  );
  for (let i = 0; i < columns.length; i++) {
    if (i < 14)
      ws.getColumn(i + 1).width =
        2 + Math.max(...rows.map((r) => (r[i] ? r[i].toString().length : 0)));
    else ws.getColumn(i + 1).width = 9;
    ws.getCell(5, i + 1).style = headerStyle;
    ws.getCell(6, i + 1).style = headerStyle;
    ws.getCell(7, i + 1).style = headerStyle;
    ws.getCell(5, i + 1).border = headerBorder;
    ws.getCell(6, i + 1).border = headerBorder;
  }
  ws.getColumn(1).hidden = true;

  ws.addTable({
    name: "MyTable",
    ref: "A7",
    headerRow: true,
    style: {
      theme: "TableStyleLight1",
      showRowStripes: true,
    },
    columns: cols,
    rows: rows,
  });

  workbook.xlsx.writeBuffer().then((data) => {
    console.log("excel file created successfully");
    let url = window.URL.createObjectURL(
      new Blob([data], { type: "application/octet-stream" })
    );
    download(url, "DSCB-TDKT.xlsx");
  });
};

export const exportABC = (staffs) => {
  var Excel = require("exceljs");
  var workbook = new Excel.Workbook();
  var ws = workbook.addWorksheet("ABCReport");
  const year = new Date().getFullYear();
  ws.fontName = fontName;
  ws.getColumn("B").alignment = { horizontal: "center" };
  ws.mergeCells("B1:AB1");
  ws.getCell("B1").value = "TRƯỜNG ĐẠI HỌC CÔNG NGHỆ";
  ws.getCell("B1").style = {
    font: { bold: true, underline: true, name: fontName, size: 12 },
    alignment: { horizontal: "center" },
  };

  ws.mergeCells("B3:AB3");
  ws.getCell("B3").value =
    "BÁO CÁO TỔNG HỢP KẾT QUẢ ĐÁNH GIÁ, XẾP LOẠI CHẤT LƯỢNG CÁN BỘ NĂM " + year;
  ws.getCell("B3").style = {
    font: { bold: true, name: fontName, size: 12 },
    alignment: { horizontal: "center" },
  };

  ws.getRow(5).values = [
    null,
    "STT",
    "Ông/Bà",
    null,
    "Chức danh",
    null,
    null,
    null,
    null,
    "Họ và tên",
    "Năm sinh",
    "Số năm công tác",
    "Số tháng công tác trong năm",
    "Chứng chỉ đã đạt",
    "Loại hợp đồng",
    "Chức vụ",
    "Đơn vị công tác",
    "Giảng dạy",
    null,
    null,
    "Nghiên cứu KH",
    null,
    null,
    "Thi đua khen thưởng",
    "Xếp loại cuối năm",
    null,
    null,
    "Thành tích",
    "Ghi chú",
  ];

  const columns = [
    null,
    null,
    null,
    null,
    "GS.TS",
    "PSG.TS",
    "TS",
    "ThS",
    "CN",
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    "Định mức",
    "Tổng giờ",
    "Tỷ lệ",
    "Định mức",
    "Tổng giờ",
    "Tỷ lệ",
    null,
    "CB tự đánh giá",
    "CNBM",
    "CNK",
    null,
    null,
  ];

  ws.getRow(6).values = columns;

  ws.mergeCells("B5:B6");
  ws.mergeCells("C5:D6");
  ws.mergeCells("E5:I5");
  ws.mergeCells("J5:J6");
  ws.mergeCells("K5:K6");
  ws.mergeCells("L5:L6");
  ws.mergeCells("M5:M6");
  ws.mergeCells("N5:N6");
  ws.mergeCells("O5:O6");
  ws.mergeCells("P5:P6");
  ws.mergeCells("Q5:Q6");
  ws.mergeCells("R5:T5");
  ws.mergeCells("U5:W5");
  ws.mergeCells("X5:X6");
  ws.mergeCells("Y5:AA5");
  ws.mergeCells("AB5:AB6");
  ws.mergeCells("AC5:AC6");

  const cols = columns.map((title, i) => ({ name: i.toString() }));
  let sorted = staffs.slice();
  sorted.sort((s1, s2) => {
    if (s1.department > s2.department) return 1;
    if (s1.department < s2.department) return -1;
    return 0;
  });
  const rows = sorted.map(
    (
      {
        id,
        sex,
        degree,
        rank,
        positions,
        firstName,
        lastName,
        department,
        startDate,
        yearOfBirth,
        contracts,
        tdkt,
      },
      index
    ) => {
      let row = columns.map((title) => null);
      row[0] = id;
      row[1] = index + 1;
      row[9] = fullName({ firstName, lastName });
      row[10] = yearOfBirth;
      row[11] = calculateDuration(startDate);
      row[14] = getCurrent(contracts);
      row[15] = getCurrent(positions);
      row[16] = department;
      if (sex === "Nam") row[2] = "Ông";
      else row[3] = "Bà";
      if (rank === "GS") row[4] = "GS.TS.";
      else if (rank === "PGS") row[5] = "PGS.TS.";
      else if (degree === "TS") row[6] = "TS.";
      else if (degree === "ThS") row[7] = "ThS.";
      else row[8] = "CN";

      row[23] = getTdktByYearFromHistory(year, tdkt);
      return row;
    }
  );
  for (let i = 0; i < columns.length; i++) {
    if (i < 14)
      ws.getColumn(i + 1).width =
        2 + Math.max(...rows.map((r) => (r[i] ? r[i].toString().length : 0)));
    else ws.getColumn(i + 1).width = 9;
    ws.getCell(5, i + 1).style = headerStyle;
    ws.getCell(6, i + 1).style = headerStyle;
    ws.getCell(7, i + 1).style = headerStyle;
    ws.getCell(5, i + 1).border = headerBorder;
    ws.getCell(6, i + 1).border = headerBorder;
  }
  ws.getColumn(1).hidden = true;

  ws.addTable({
    name: "MyTable",
    ref: "A7",
    headerRow: true,
    style: {
      theme: "TableStyleLight1",
      showRowStripes: true,
    },
    columns: cols,
    rows: rows,
  });

  workbook.xlsx.writeBuffer().then((data) => {
    console.log("excel file created successfully");
    let url = window.URL.createObjectURL(
      new Blob([data], { type: "application/octet-stream" })
    );
    download(url, "DSCB-ABC.xlsx");
  });
};

export const exportSummaryList = (staffs) => {
  var Excel = require("exceljs");
  var workbook = new Excel.Workbook();
  var ws = workbook.addWorksheet("DS_TongHop");
  const year = new Date().getFullYear();

  ws.fontName = fontName;

  ws.mergeCells("B3:AB3");
  ws.getCell("B3").value =
    "TỔNG HỢP DỮ LIỆU CÁN BỘ KHOA CÔNG NGHỆ THÔNG TIN NĂM " + year;
  ws.getCell("B3").style = {
    font: { bold: true, name: fontName, size: 12 },
    alignment: { horizontal: "center" },
  };

  ws.getRow(5).values = [
    null,
    "STT",
    "Ông/Bà",
    null,
    "Chức danh",
    null,
    null,
    null,
    null,
    "Họ và tên",
    "Năm sinh",
    "Số năm công tác",
    "Số tháng công tác trong năm",
    "Chứng chỉ đã đạt",
    "Loại hợp đồng",
    "Chức vụ",
    "Đơn vị công tác",
    "Giảng dạy",
    null,
    null,
    "Nghiên cứu KH",
    null,
    null,
    "Xếp loại cuối năm",
    "Thi đua khen thưởng",
    null,
    null,

    "Thành tích",
    "Ghi chú",
  ];

  const columns = [
    null,
    null,
    null,
    "GS.TS",
    "PSG.TS",
    "TS",
    "ThS",
    "CN",
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    "Định mức",
    "Tổng giờ",
    "Tỷ lệ",
    "Định mức",
    "Tổng giờ",
    "Tỷ lệ",
    null,
    "LĐTT",
    "CSTĐ",
    "",
    null,
    null,
  ];

  ws.getRow(6).values = columns;

  ws.mergeCells("B5:B6");
  ws.mergeCells("C5:D6");
  ws.mergeCells("E5:I5");
  ws.mergeCells("J5:J6");
  ws.mergeCells("K5:K6");
  ws.mergeCells("L5:L6");
  ws.mergeCells("M5:M6");
  ws.mergeCells("N5:N6");
  ws.mergeCells("O5:O6");
  ws.mergeCells("P5:P6");
  ws.mergeCells("Q5:Q6");
  ws.mergeCells("R5:T5");
  ws.mergeCells("U5:W5");
  ws.mergeCells("X5:X6");
  ws.mergeCells("Y5:AA5");
  ws.mergeCells("AB5:AB6");
  ws.mergeCells("AC5:AC6");

  const cols = columns.map((title, i) => ({ name: i.toString() }));
  let sorted = staffs.slice();
  sorted.sort((s1, s2) => {
    if (s1.department > s2.department) return 1;
    if (s1.department < s2.department) return -1;
    return 0;
  });
  const rows = sorted.map(
    (
      {
        id,
        sex,
        yearOfBirth,
        degree,
        rank,
        positions,
        firstName,
        lastName,
        department,
        contracts,
        startDate,
        abc,
      },
      index
    ) => {
      let row = columns.map((title) => null);
      row[0] = id;
      row[1] = index + 1;
      row[9] = fullName({ firstName, lastName });
      row[10] = yearOfBirth;
      row[11] = calculateDuration(startDate);
      row[14] = getCurrent(contracts);
      row[15] = getCurrent(positions);
      row[16] = department;
      row[23] = getAbcByYearFromHistory(year - 1, abc);
      if (sex === "Nam") row[2] = "Ông";
      else row[3] = "Bà";
      if (rank === "GS") row[4] = "GS.TS.";
      else if (rank === "PGS") row[5] = "PGS.TS.";
      else if (degree === "TS") row[6] = "TS.";
      else if (degree === "ThS") row[7] = "ThS.";
      else row[8] = "CN";
      return row;
    }
  );
  for (let i = 0; i < columns.length; i++) {
    if (i > 9 && i < 15) ws.getColumn(i + 1).width = 10;
    else if (i < 17)
      ws.getColumn(i + 1).width =
        2 + Math.max(...rows.map((r) => (r[i] ? r[i].toString().length : 0)));
    else ws.getColumn(i + 1).width = 9;
    ws.getCell(5, i + 1).style = headerStyle;
    ws.getCell(6, i + 1).style = headerStyle;
    ws.getCell(7, i + 1).style = headerStyle;
    ws.getCell(5, i + 1).border = headerBorder;
    ws.getCell(6, i + 1).border = headerBorder;
  }
  ws.getColumn(1).hidden = true;

  ws.addTable({
    name: "MyTable",
    ref: "A7",
    headerRow: true,
    style: {
      theme: "TableStyleLight1",
      showRowStripes: true,
    },
    columns: cols,
    rows: rows,
  });

  workbook.xlsx.writeBuffer().then((data) => {
    console.log("excel file created successfully");
    let url = window.URL.createObjectURL(
      new Blob([data], { type: "application/octet-stream" })
    );
    download(url, "DSCB-TongHop.xlsx");
  });
};
