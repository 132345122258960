import React, { Fragment } from "react";
import { ExpandMore, ExpandLess } from "@material-ui/icons";
import moment from "moment";
import { IconButton, Grid, Divider, Typography } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import EditableTable from "../EditableTable";

function comp(pos1, pos2) {
  if (moment(pos1.fromDate).isBefore(pos2.fromDate)) return 1;
  if (moment(pos1.fromDate).isAfter(pos2.fromDate)) return -1;
  return 0;
}

const useStyles = makeStyles((theme) => ({
  list: {
    width: "100%",
    maxWidth: "36ch",
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: "inline",
  },
}));

const History = ({
  title,
  summary,
  items,
  onChange,
  columns,
  expanded,
  handleToggleExpanded,
}) => {
  const handleDataChange = (newItems) => {
    onChange(newItems);
  };

  const current = summary;

  const valueColumn = columns.find(
    (col) => col.field === "value" && col.type === "select" && col.optionMap
  );
  const currentData = valueColumn ? valueColumn.optionMap[current] : current;
  const header = title + " " + (currentData ? currentData : "");

  return (
    <Fragment>
      <Divider />
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Grid item>
          <Typography>{header}</Typography>
        </Grid>

        <Grid item>
          {expanded ? (
            <IconButton onClick={handleToggleExpanded} className="button">
              <ExpandLess color="primary" />
            </IconButton>
          ) : (
            <IconButton onClick={handleToggleExpanded} className="button">
              <ExpandMore color="primary" />
            </IconButton>
          )}
        </Grid>
      </Grid>

      {expanded && (
        <EditableTable
          columns={columns}
          items={items ? items : []}
          handleDataChange={handleDataChange}
        />
      )}
    </Fragment>
  );
};

export default History;
