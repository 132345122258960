import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers/rootReducer";

import { composeWithDevTools } from "redux-devtools-extension";

const initialState = {};

export default function store() {
  return createStore(
    rootReducer,
    initialState,
    composeWithDevTools(
      applyMiddleware(thunk)
      // other store enhancers if any
    )
  );
}
