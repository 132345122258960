import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useFirebase } from 'react-redux-firebase';
import { useHistory } from 'react-router-dom';
import { styles } from '../constants/styles';
import { 
	TextField, 
	Paper,
	Button,
	Grid,
	Typography
} from '@material-ui/core'
import { SIGN_IN } from '../constants/routes';

const useStyles = makeStyles(styles);
   
const PasswordForgetForm = () => {

	const firebase = useFirebase();
	const history = useHistory();
	
	const [error, setError] = React.useState(null);
	const [email, setEmail] = React.useState('');

	const handleSubmit = ((event) => {  
		firebase.resetPassword(email)
		.then(() => {
			console.log("reset "+ email);
			history.push(SIGN_IN);
		})
		.catch(error => {
		  setError(error);
		  console.log(error)		  
		});    	
  	});
   
	const handleChange = event => {
	  setEmail(event.target.value);
	};
   
	const classes = useStyles();	
   
	return (
		<Grid container spacing={3} justify="center" alignItems="center">
				<Grid item xs={12} sm={6} md={4}>	  
					<Paper className={classes.paper}>
						<Grid container spacing={3}>
							{ !!error && (
								<Grid item xs={12}>	  
									<Typography variant="body2" color='error'>
										Email không hợp lệ 
									</Typography>
			  				</Grid>
								)
							}		    		
		    			
							<Grid item xs={12}>	  
								<TextField
									id='email'
					        label='Email'
					        className={classes.textField}
					        margin="normal"
					        fullWidth
					        onChange={handleChange}
					        variant="outlined"
					  		/>
			  			</Grid>

			        <Grid item xs={12}>	  
								<Button variant="contained" color="primary" onClick={handleSubmit}>
								Đặt lại mật khẩu
								</Button>
							</Grid>
							
						</Grid>
					</Paper>
				</Grid>
			</Grid>

	  );
}
   
  export default PasswordForgetForm;
   