export const STAFF_TYPES = [
  { value: "CV", label: "Chuyên viên" },
  { value: "GV", label: "Giảng viên" },
  { value: "GVC", label: "Giảng viên chính" },
  { value: "GVCC", label: "Giảng viên cao cấp" },
  { value: "GVTH", label: "Giảng viên thực hành" },
  { value: "NCV", label: "Nghiên cứu viên" },
  { value: "TTS", label: "Thực tập sinh" },
];

export const DEGREES = [
  { value: "TS", label: "Tiến sỹ" },
  { value: "ThS", label: "Thạc sỹ" },
  { value: "ĐH", label: "Đại học" },
];

export const RANKS = [
  { value: null, label: "-" },
  { value: "GS", label: "Giáo sư" },
  { value: "PGS", label: "Phó giáo sư" },
];

export const SEXES = [
  { value: "Nam", label: "Nam" },
  { value: "Nữ", label: "Nữ" },
];

export const STATUS_MAP = {
  VC: "Viên chức",
  HD: "Hợp đồng",
  TN_T: "Tạo nguồn Trường",
  TN_DT: "Tạo nguồn Đề tài",
  QL: "CBQL có giảng dạy",
  KN: "Kiêm nhiệm",
  NN: "Đi nước ngoài dài hạn",
  HT: "Hưu trí",
  EX: "Chấm dứt hợp đồng",
};

export const DEPARTMENTS = [
  "Công nghệ Phần mềm",
  "Hệ thống Thông tin",
  "Khoa học Máy tính",
  "Khoa học và Kỹ thuật tính toán",
  "Mạng và Truyền thông máy tính",
  "PTN An toàn Thông tin",
  "PTN Công nghệ Tri thức",
  "PTN Hệ thống nhúng",
  "PTN Tương tác Người-Máy",
  "Văn phòng Khoa",
];
