export const SALARY_HISTORY_TABLE = [
  {
    field: "fromDate",
    headerName: "Từ ngày",
    type: "date",
    required: true,
    editable: true,
  },
  {
    field: "value",
    headerName: "Hệ số lương",
    width: 150,
    editable: true,
    required: true,
  },
  {
    field: "type",
    headerName: "Hình thức",
    width: 150,
    editable: true,
    defaultValue: "TX",
    type: "select",
    optionMap: {
      TX: "Thường xuyên",
      TTH: "Trước thời hạn",
    },
    options: [
      { value: "TX", label: "Thường xuyên" },
      { value: "TTH", label: "Trước thời hạn" },
    ],
  },
  {
    field: "note",
    headerName: "Ghi chú",
    width: 150,
    editable: true,
  },
];

export const POSITION_HISTORY_TABLE = [
  {
    field: "fromDate",
    headerName: "Từ ngày",
    type: "date",
    required: true,
    editable: true,
  },
  {
    field: "toDate",
    headerName: "Đến ngày",
    type: "date",
    editable: true,
  },
  {
    field: "value",
    headerName: "Chức vụ",
    width: 150,
    editable: true,
    required: true,
    type: "select",
    options: [
      { value: "Phó Chủ nhiệm Bộ môn", label: "Phó Chủ nhiệm Bộ môn" },
      { value: "Chủ nhiệm Bộ môn", label: "Chủ nhiệm Bộ môn" },
      { value: "Phó Chủ nhiệm Khoa", label: "Phó Chủ nhiệm Khoa" },
      { value: "Chủ nhiệm Khoa", label: "Chủ nhiệm Khoa" },
      { value: "Trưởng Phòng thí nghiệm", label: "Trưởng Phòng thí nghiệm" },
      { value: "Phó Phòng thí nghiệm", label: "Phó Phòng thí nghiệm" },
      { value: "Chủ tịch Công đoàn Khoa", label: "Chủ tịch Công đoàn Khoa" },
      { value: "Bí thư Đoàn TN", label: "Bí thư Đoàn TN" },
    ],
  },
  {
    field: "number",
    headerName: "Quyết định số",
    width: 150,
    editable: true,
  },
];

export const CONTRACT_HISTORY_TABLE = [
  {
    field: "fromDate",
    headerName: "Từ ngày",
    type: "date",
    required: true,
    editable: true,
  },
  {
    field: "toDate",
    headerName: "Đến ngày",
    type: "date",
    editable: true,
  },
  {
    field: "value",
    headerName: "Loại hợp đồng",
    width: 150,
    editable: true,
    required: true,
    type: "select",
    optionMap: {
      BC: "Biên chế",
      HĐLĐ: "Hợp đồng lao động",
      HĐLĐ_1: "Hợp đồng lao động (1 năm)",
      HĐLĐ_3: "Hợp đồng lao động (3 năm)",
      HĐLV: "Hợp đồng làm việc",
      HĐLĐ_TN: "Hợp đồng tạo nguồn (trường trả lương)",
      HĐLĐ_ĐT: "Hợp đồng tạo nguồn (đề tài trả lương)",
      CBKN: "Cán bộ kiêm nhiệm",
    },
    options: [
      { value: "BC", label: "Biên chế" },
      { value: "HĐLĐ", label: "Hợp đồng lao động" },
      { value: "HĐLĐ_1", label: "Hợp đồng lao động (1 năm)" },
      { value: "HĐLĐ_3", label: "Hợp đồng lao động (3 năm)" },
      { value: "HĐLV", label: "Hợp đồng làm việc" },
      { value: "HĐLĐ_TN", label: "Hợp đồng tạo nguồn (trường trả lương)" },
      { value: "HĐLĐ_ĐT", label: "Hợp đồng tạo nguồn (đề tài)" },
      { value: "CBKN", label: "Cán bộ kiêm nhiệm" },
    ],
  },
  {
    field: "number",
    headerName: "Số",
    width: 150,
    editable: true,
  },
];

export const TRAVEL_HISTORY_TABLE = [
  {
    field: "value",
    headerName: "Nội dung",
    width: 150,
    editable: true,
    required: true,
  },
  {
    field: "fromDate",
    headerName: "Từ ngày",
    type: "date",
    editable: true,
    required: true,
  },
  {
    field: "toDate",
    headerName: "Đến ngày",
    type: "date",
    editable: true,
  },
  {
    field: "fundings",
    headerName: "Kinh phí",
    width: 150,
    editable: true,
  },
  {
    field: "number",
    headerName: "Quyết định số",
    width: 150,
    editable: true,
  },
];

export const LEAVE_HISTORY_TABLE = [
  {
    field: "fromDate",
    headerName: "Từ ngày",
    type: "date",
    required: true,
    editable: true,
  },
  {
    field: "toDate",
    headerName: "Đến ngày",
    type: "date",
    editable: true,
  },
  {
    field: "value",
    headerName: "Loại nghỉ chế độ",
    width: 150,
    editable: true,
    required: true,
  },
];

export const CERTIFICATE_HISTORY_TABLE = [
  {
    field: "fromDate",
    headerName: "Ngày",
    type: "date",
    required: false,
    editable: true,
  },
  {
    field: "value",
    headerName: "Chứng chỉ",
    type: "freeSolo",
    editable: true,
    required: true,
    options: [
      "Nghiệp vụ Sư phạm Đại học",
      "Giảng viêng chính hạng I",
      "Giảng viêng chính hạng II",
      "Cao cấp lý luận chính trị",
      "Trung cấp lý luận chính trị",
      "Bồi dưỡng kiến thức quản lý nhà nước",
    ],
  },
  {
    field: "note",
    headerName: "Ghi chú",
    editable: true,
  },
];
