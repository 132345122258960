import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useFirebase, isLoaded, isEmpty } from "react-redux-firebase";
import { useSelector } from "react-redux";
import { useHistory, Link, useLocation, Redirect } from "react-router-dom";
import { styles } from "../constants/styles";
import {
  AppBar,
  Toolbar,
  TextField,
  Paper,
  Button,
  Grid,
  Typography,
} from "@material-ui/core";
import { HOME, PASSWORD_FORGET } from "../constants/routes";

const useStyles = makeStyles(styles);

const SignIn = () => {
  const [credentials, setCredentials] = React.useState({
    email: "",
    password: "",
  });
  const [error, setError] = React.useState(null);

  const firebase = useFirebase();
  const history = useHistory();
  const { state } = useLocation();

  const handleChange = (event) => {
    const newCredentials = credentials;
    newCredentials[event.target.id] = event.target.value;
    setCredentials(newCredentials);
  };

  const handleSubmit = (event) => {
    firebase
      .login(credentials)
      .then(() => {
        console.log("Logged in");
        history.replace(state.location?.pathname ?? HOME);
      })
      .catch((error) => {
        setError(error);
        console.log(error);
      });
  };

  const classes = useStyles();
  const auth = useSelector((state) => state.firebase.auth);

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            Hệ thống Quản lý Cán bộ Khoa CNTT
          </Typography>
        </Toolbar>
      </AppBar>

      {isLoaded(auth) && !isEmpty(auth) && <Redirect to="/home" />}
      <Grid container spacing={3} justify="center" alignItems="center">
        <Grid item xs={12} sm={6} md={4}>
          <Paper className={classes.paper}>
            <Grid container spacing={3}>
              {!!error && (
                <Grid item xs={12}>
                  <Typography variant="body2" color="error">
                    Email hoặc mật khẩu sai.
                  </Typography>
                </Grid>
              )}

              <Grid item xs={12}>
                <TextField
                  id="email"
                  label="Email"
                  className={classes.textField}
                  margin="normal"
                  fullWidth
                  onChange={handleChange}
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  id="password"
                  label="Mật khẩu"
                  type="password"
                  autoComplete="current-password"
                  fullWidth
                  onChange={handleChange}
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                >
                  Đăng nhập
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Link to={PASSWORD_FORGET}>Quên mật khẩu?</Link>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default SignIn;
