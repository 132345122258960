import React, { useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Typography,
  TableRow,
  TableHead,
  TableCell,
  Table,
  TableBody,
  Grid,
  Button,
} from "@material-ui/core";
import { Save, Undo, Error, Publish } from "@material-ui/icons";
import { useSelector } from "react-redux";
import {
  isLoaded,
  isEmpty,
  useFirestoreConnect,
  useFirestore,
} from "react-redux-firebase";

import "../index.css";
import * as XLSX from "xlsx";
import { fullName, splitName } from "../utils/staffUtils";
import { styles } from "../constants/styles";

const useStyles = makeStyles(styles);

export default function ImportABCPage() {
  const [items, setItems] = useState([]);
  const [errorReadingFile, setErrorReadingFile] = useState(false);
  const classes = useStyles();

  useFirestoreConnect([{ collection: "staffs" }]);
  const firestore = useFirestore();
  const staffs = useSelector((state) => state.firestore.ordered.staffs);

  const valid = (items) => {
    if (items.length <= 0) {
      console.log(items.length);
      return false;
    }
    return true;
  };

  const findColumnIndex = (labelPrefix, row1, row2) => {
    const res1 = row1.findIndex(
      (col) => col && col.trim().startsWith(labelPrefix)
    );
    if (res1 !== -1) return res1;
    const res2 = row2.findIndex(
      (col) => col && col.trim().startsWith(labelPrefix)
    );
    return res2;
  };

  const readExcel = (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;
        const wb = XLSX.read(bufferArray, { type: "buffer", cellStyles: true });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
        resolve(data);
      };

      fileReader.onerror = (error) => {
        console.log(error);
        reject(error);
      };
    });

    promise.then((d) => {
      const STT_i = d.findIndex((row) => row[1] === "STT");
      const Name_j = findColumnIndex("Họ và", d[STT_i], d[STT_i + 1]);
      const Dept_j = findColumnIndex("Đơn vị", d[STT_i], d[STT_i + 1]);
      const ABC_j = findColumnIndex("CNK", d[STT_i], d[STT_i + 1]);
      const Note_j = findColumnIndex("Thành tích", d[STT_i], d[STT_i + 1]);
      console.log(d[STT_i][Name_j], d[STT_i + 1][ABC_j], d[STT_i][Note_j]);

      const start_i =
        d[STT_i + 2][Name_j].trim().length < 3 ? STT_i + 3 : STT_i + 2;
      const table = d.slice(start_i);
      const end_i = table.findIndex((row) => !row[0]);
      console.log(d[start_i]);
      console.log(table[end_i - 1]);

      const build = (row) => ({
        id: row[0],
        name: row[Name_j].trim(),
        dept: row[Dept_j] ? row[Dept_j].trim() : null,
        awards: row[ABC_j]
          ? row[ABC_j].replace(/[\u0000-\u001F\u007F-\u009F]/g, "").trim()
          : "-",
        note: row[Note_j] ? row[Note_j] : "",
      });

      const results = table.slice(0, end_i).map((row) => build(row));
      console.log(results);
      if (valid(results)) {
        setItems(results);
        setErrorReadingFile(false);
      } else setErrorReadingFile(true);
    });
  };

  const displayResults = (staffs, year) => {
    let lines = [];
    staffs.forEach((staff) => {
      const tdkt = !staff.abc ? null : staff.abc[year];
      if (tdkt) {
        const { awards, note } = tdkt;
        lines.push({
          name: fullName(staff),
          dept: staff.department,
          awards: awards,
          note: note,
        });
      }
    });
    return lines;
  };

  const idToAbcMap = () => {
    let map = {};
    items.forEach(({ id, awards, note }) => {
      if (id) map[id] = { awards: awards, note: note };
    });
    return map;
  };

  const save = async (year) => {
    const map = idToAbcMap();
    const batch = firestore.batch();
    const staffRef = firestore.collection("staffs");

    const snapshot = await staffRef.get();

    snapshot.forEach((doc) => {
      console.log(doc.id, "=>", doc.data());
      var newABC = doc.data().abc;
      if (!newABC) newABC = {};
      console.log(newABC);

      const thisYearABC = map[doc.id];
      if (thisYearABC) {
        newABC[year] = thisYearABC;
        const docRef = staffRef.doc(doc.id);
        console.log(newABC);
        batch.update(docRef, { abc: newABC });
      } else if (newABC[year]) {
        newABC[year] = null;
        const docRef = staffRef.doc(doc.id);
        batch.update(docRef, { abc: newABC });
      }
    });

    await batch.commit();
    console.log("Committed ");
    setItems([]);
  };

  const reject = () => {
    setItems([]);
  };

  const handleError = () => {
    setErrorReadingFile(false);
  };

  const TdktTable = (items) => (
    <div className={classes.table_wrapper}>
      {items.length > 0 && (
        <Table stickyHeader size="small" id="sheetjs">
          <TableHead>
            <TableRow>
              <TableCell align="center">STT</TableCell>
              <TableCell>Họ và tên</TableCell>
              <TableCell>Đơn vị</TableCell>
              <TableCell>Xếp loại</TableCell>
              <TableCell>Thành tích</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map(({ name, dept, awards, note }, index) => (
              <TableRow hover key={index + 1}>
                <TableCell align="center">{index + 1}</TableCell>
                <TableCell>{name}</TableCell>
                <TableCell>{dept}</TableCell>
                <TableCell>{awards}</TableCell>
                <TableCell>{note}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </div>
  );

  const year = new Date().getFullYear() - 1;

  const theme = useTheme();
  const { primary } = theme.palette;

  return (
    <div>
      <div style={{ color: primary.dark, backgroundColor: primary[50] }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" noWrap>
              {items.length === 0
                ? "Kết quả xếp loại ABC năm ".concat(year)
                : "Kết quả từ file"}
            </Typography>
          </Grid>
          <Grid item xs={12} md={2}>
            <Button component="label" color="primary" startIcon={<Publish />}>
              Nhập từ file
              <input
                type="file"
                accept=".xlsx"
                hidden
                onChange={(e) => {
                  const file = e.target.files[0];
                  readExcel(file);
                }}
              />
            </Button>
          </Grid>
          {errorReadingFile && (
            <Grid item xs={12} md={2}>
              <Button
                onClick={() => handleError()}
                color="secondary"
                startIcon={<Error />}
              >
                Lỗi xử lý file
              </Button>
            </Grid>
          )}
          {items.length > 0 && (
            <Grid item xs={12} md={2}>
              <Button
                onClick={() => save(year)}
                color="primary"
                startIcon={<Save />}
              >
                Ghi nhận kết quả
              </Button>
              <Button
                onClick={() => reject()}
                color="secondary"
                startIcon={<Undo />}
              >
                Bỏ
              </Button>
            </Grid>
          )}
        </Grid>
      </div>

      {items.length > 0
        ? TdktTable(items)
        : !isLoaded(staffs)
        ? "Đang tải..."
        : isEmpty(staffs)
        ? "Danh sách rỗng"
        : TdktTable(displayResults(staffs, year))}
    </div>
  );
}
