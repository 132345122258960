import moment from "moment";

export const splitName = (name) => {
  const splitIndex = name.lastIndexOf(" ");
  return {
    firstName: name.substring(splitIndex + 1),
    lastName: name.substring(0, splitIndex),
  };
};

export const getCurrent = (list) => {
  if (typeof list === "undefined") return "";

  for (let i = 0; i < list.length; i++) {
    const { value, fromDate, toDate } = list[i];
    if (!toDate || moment().isBetween(fromDate, toDate)) return value;
  }
  return "";
};

export const getLatest = (list) => {
  if (typeof list === "undefined" || list.length === 0) return null;

  let maxIndex = 0;
  let maxDate = moment(list[0].fromDate);
  for (let i = 1; i < list.length; i++) {
    const { fromDate } = list[i];
    if (moment(fromDate).isAfter(maxDate)) {
      maxIndex = i;
      maxDate = moment(fromDate);
    }
  }
  return list[maxIndex];
};

export const getMultiCurrent = (list) => {
  if (typeof list === "undefined") return "";

  const today = new Date();
  const currents = list
    .filter(
      ({ toDate }) =>
        toDate === "undefined" || !toDate || moment(toDate).isAfter(today)
    )
    .map(({ value }) => value);
  return currents && currents.length > 0 ? currents.join(", ") : "";
};

const containsAnyOf = (items, keys) => {
  if (keys.length === 0 && items.length === 0) return true;

  for (let i = 0; i < items.length; i++) {
    if (keys.indexOf(items[i]) > -1) return true;
  }
  return false;
};

export const filter = (staffs, searchKey, { withPosition, selectedStatuses }) =>
  staffs.filter((staff) => {
    const {
      firstName,
      lastName,
      rankDegree,
      rank,
      degree,
      type,
      contracts,
      positions,
      department,
      status,
    } = staff;
    const position = getCurrent(positions);
    const contractType = getCurrent(contracts);
    const checkPosition = !withPosition || position;
    const checkStatus =
      selectedStatuses.length === 0 ||
      (status && containsAnyOf(status, selectedStatuses));

    const checkKey =
      !searchKey ||
      searchKey === "" ||
      (lastName + " " + firstName).toLowerCase().includes(searchKey) ||
      (!!rankDegree && rankDegree.toLowerCase().includes(searchKey)) ||
      (!!rank && rank.toLowerCase().includes(searchKey)) ||
      (!!degree && degree.toLowerCase().includes(searchKey)) ||
      (!!type && type.toLowerCase().includes(searchKey)) ||
      (!!contractType && contractType.toLowerCase().includes(searchKey)) ||
      (!!position && position.toLowerCase().includes(searchKey)) ||
      (!!department && department.toLowerCase().includes(searchKey));

    return checkKey && checkStatus && checkPosition;
  });

export const filterByCertificates = (staffs, searchKey, state) =>
  staffs.filter((staff) => {
    const { certificates, status } = staff;

    const withState = status
      ? containsAnyOf(status, state.selectedStatuses)
      : state.selectedStatuses.length === 0;

    const withKey =
      !searchKey ||
      searchKey === "" ||
      state.includesKeyword ===
        displayCertificates(certificates).toLowerCase().includes(searchKey);

    return withKey && withState;
  });

const convert = (s) =>
  s
    .replaceAll("Ch", "Cz")
    .replaceAll("Gh", "Gz")
    .replaceAll("Ng", "Ny")
    .replaceAll("Ngh", "Nyy")
    .replaceAll("Nh", "Nz")
    .replaceAll("Ph", "Pz")
    .replaceAll("Th", "Tz")
    .replaceAll("Tr", "Tzz");

const compareVnese = (a, b) => {
  const a1 = convert(a);
  const b1 = convert(b);
  return a1.localeCompare(b1, "vi-vn", { ignorePunctuation: false });
};

export function staffCompare(staff1, staff2) {
  const firstCompare = compareVnese(staff1.firstName, staff2.firstName);
  if (firstCompare > 0) return 1;
  if (firstCompare < 0) return -1;
  const lastCompare = compareVnese(staff1.firstName, staff2.firstName);
  if (lastCompare > 0) return 1;
  if (lastCompare < 0) return -1;
  return 0;
}

export const getAbc = (staff, year) => {
  const { ABCs } = staff;
  return !!ABCs ? ABCs[year] : "";
};

export const getAbcByYearFromHistory = (year, ABCs) => {
  if (!ABCs) return "";
  const abc = ABCs[year];
  return abc ? abc.awards : "";
};

export const getTdktByYearFromHistory = (year, TDKTs) => {
  if (!TDKTs) return "";
  const res = TDKTs[year];
  return res ? res.awards.join(" ,") : "";
};

export const displayList = (list, MAP) =>
  list ? list.map((item) => MAP[item]).join(", ") : "";

export const displayCertificates = (certificates) =>
  certificates ? certificates.map((c) => c.value).join(", ") : "";

export const fullName = ({ firstName, lastName }) => lastName + " " + firstName;

export const calculateDuration = (startDate) => {
  if (!startDate) return "";

  const startMoment = moment(startDate);
  const diff = startMoment.diff(moment(new Date()));
  const years = moment.duration(diff).years();
  return (years < 0 ? -years : 0) + " năm";
};
