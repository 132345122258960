import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Tabs,
  Tab,
  Typography,
  Box,
  IconButton,
  Toolbar,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { AccountCircle } from "@material-ui/icons";

import StaffListPage from "./StaffList";
import TdktPage from "./TdktPage";
import ImportAbcPage from "./ImportABCPage";
import Certificates from "./Certificates";
import OfficePositions from "./OfficePositions";
import FiveYears from "./FiveYears";

import { useFirebase, isLoaded, isEmpty } from "react-redux-firebase";
import { useSelector } from "react-redux";

import Editable from "./EditTable";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  rightToolbar: {
    marginLeft: "auto",
    marginRight: -12,
  },
}));

export default function SimpleTabs() {
  const classes = useStyles();
  const firebase = useFirebase();

  const [value, setValue] = React.useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const auth = useSelector((state) => state.firebase.auth);
  const profile = useSelector((state) => state.firebase.profile);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    firebase.logout();
    setAnchorEl(null);
  };

  const isMenuOpen = Boolean(anchorEl);
  const menuId = "primary-search-account-menu";
  const { displayName, role, permissions } = profile;
  console.log(permissions);
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>
        {displayName} {auth.email}
      </MenuItem>
      <MenuItem onClick={handleMenuClose}>Đổi mật khẩu</MenuItem>
      <MenuItem onClick={handleLogout}>Thoát</MenuItem>
    </Menu>
  );

  return (
    <div className={classes.root}>
      <AppBar position="static">
        {isLoaded(auth) && !isEmpty(auth) && (
          <Toolbar>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="simple tabs example"
            >
              <Tab label="Danh sách cán bộ" {...a11yProps(0)} />
              <Tab label="Chứng chỉ" {...a11yProps(1)} />
              <Tab label="Thi đua khen thưởng" {...a11yProps(2)} />
              <Tab label="Đánh giá ABC" {...a11yProps(3)} />
              <Tab label="Thành tích" {...a11yProps(4)} />
              <Tab label="Bổ nhiệm" {...a11yProps(5)} />
            </Tabs>

            <section className={classes.rightToolbar}>
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
            </section>
          </Toolbar>
        )}
      </AppBar>
      {renderMenu}

      <TabPanel value={value} index={0}>
        <StaffListPage />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Certificates />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <TdktPage />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <ImportAbcPage />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <FiveYears />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <OfficePositions />
      </TabPanel>
      <TabPanel value={value} index={6}>
        <Typography>Under Construction</Typography>
        <Editable />
      </TabPanel>
    </div>
  );
}
