import React, { Fragment } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import SignInPage from "./SignIn";
import PasswordForgetPage from "./PasswordForget";

import TabView from "./TabView";

import { Provider } from "react-redux";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";
import { createFirestoreInstance } from "redux-firestore";
import firebase from "firebase";
import "firebase/firestore";

import * as ROUTES from "../constants/routes";
import createReduxStore from "../store";
import { PrivateRoute } from "./Authen";

import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import Moment from "@date-io/moment";

const fbConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
};

const store = createReduxStore();
const rrfProps = {
  firebase,
  config: {
    userProfile: "users",
    useFirestoreForProfile: true,
    profileParamsToPopulate: ["contacts:users"],
  },
  dispatch: store.dispatch,
  createFirestoreInstance,
};

firebase.initializeApp(fbConfig);
firebase.firestore();

const App = () => (
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>
      <Router>
        <Fragment>
          <CssBaseline />
          <MuiPickersUtilsProvider utils={Moment}>
            {/*             
                  <Navigation />       */}
            <Switch>
              <Route path={ROUTES.SIGN_IN} component={SignInPage} />
              <Route
                path={ROUTES.PASSWORD_FORGET}
                component={PasswordForgetPage}
              />
              <PrivateRoute path="*">
                <TabView />
              </PrivateRoute>
            </Switch>
          </MuiPickersUtilsProvider>
        </Fragment>
      </Router>
    </ReactReduxFirebaseProvider>
  </Provider>
);

export default App;
