import React, { useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Typography,
  TableRow,
  TableHead,
  TableCell,
  Table,
  TableBody,
  Grid,
  Button,
} from "@material-ui/core";
import { Save, Undo, Error, Publish } from "@material-ui/icons";
import { useSelector } from "react-redux";
import {
  isLoaded,
  isEmpty,
  useFirestoreConnect,
  useFirestore,
} from "react-redux-firebase";

import "../index.css";
import * as XLSX from "xlsx";
import { fullName } from "../utils/staffUtils";
import { styles } from "../constants/styles";

const useStyles = makeStyles(styles);

export default function TdktPage() {
  const [items, setItems] = useState([]);
  const [errorReadingFile, setErrorReadingFile] = useState(false);
  const classes = useStyles();

  useFirestoreConnect([{ collection: "staffs" }]);
  const firestore = useFirestore();
  const staffs = useSelector((state) => state.firestore.ordered.staffs);

  const valid = (items) => {
    if (items.length <= 0) {
      console.log(items.length);
      return false;
    }
    var ids = new Set();
    staffs.forEach(({ id }) => ids.add(id));
    for (let i in items) {
      if (!ids.has(items[i].id)) {
        console.log(i + " no id");
        return false;
      }
    }
    return true;
  };

  const readExcel = (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;
        const wb = XLSX.read(bufferArray, { type: "buffer", cellStyles: true });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
        resolve(data);
      };

      fileReader.onerror = (error) => {
        console.log(error);
        reject(error);
      };
    });

    promise.then((d) => {
      const firstRow = d.findIndex((row) => row[1] === 1 && row[0]);
      console.log(firstRow);
      const headings = d[firstRow - 2];
      console.log(headings);
      const firstCol = headings.findIndex(
        (col) => col && col.trim() === "LĐTT"
      );
      console.log(firstCol);
      const lastCol = headings.length;
      console.log(lastCol);

      const build = (row) => {
        var awards = [];
        row.forEach((currentValue, index) => {
          if (currentValue && index >= firstCol && index < lastCol)
            awards = awards.concat([headings[index]]);
        });
        const note = row[lastCol] ? row[lastCol] : "";
        return {
          id: row[0],
          name: row[9],
          dept: row[firstCol - 1],
          awards: awards,
          note: note,
        };
      };
      const data = d.slice(firstRow);
      const isEnd = (row) => !row[0];
      const end = data.findIndex(isEnd);

      const results = data.slice(0, end).map((row) => build(row));
      console.log(results);
      if (valid(results)) {
        setItems(results);
        setErrorReadingFile(false);
      } else setErrorReadingFile(true);
    });
  };

  const displayResults = (staffs, year) => {
    let lines = [];
    staffs.forEach((staff) => {
      const tdkt = !staff.tdkt ? null : staff.tdkt[year];
      if (tdkt) {
        const { awards, note } = tdkt;
        lines.push({
          name: fullName(staff),
          dept: staff.department,
          awards: awards,
          note: note,
        });
      }
    });
    return lines;
  };

  const idToTdktMap = () => {
    let map = {};
    items.forEach(({ id, awards, note }) => {
      if (id) map[id] = { awards: awards, note: note };
    });
    return map;
  };

  const save = async (year) => {
    const map = idToTdktMap();
    const batch = firestore.batch();
    const staffRef = firestore.collection("staffs");

    const snapshot = await staffRef.get();
    snapshot.forEach((doc) => {
      console.log(doc.id, "=>", doc.data());
      var newTdkt = doc.data().tdkt;
      if (!newTdkt) newTdkt = {};
      console.log(newTdkt);

      const thisYearTdkt = map[doc.id];
      if (thisYearTdkt) {
        newTdkt[year] = thisYearTdkt;
        const docRef = staffRef.doc(doc.id);
        console.log(newTdkt);
        batch.update(docRef, { tdkt: newTdkt });
      } else if (newTdkt[year]) {
        newTdkt[year] = null;
        const docRef = staffRef.doc(doc.id);
        batch.update(docRef, { tdkt: newTdkt });
      }
    });

    await batch.commit();
    console.log("Committed ");
    setItems([]);
  };

  const reject = () => {
    setItems([]);
  };

  const handleError = () => {
    setErrorReadingFile(false);
  };

  const TdktTable = (items) => (
    <div className={classes.table_wrapper}>
      {items.length > 0 && (
        <Table stickyHeader size="small" id="sheetjs">
          <TableHead>
            <TableRow>
              <TableCell align="center">STT</TableCell>
              <TableCell>Họ và tên</TableCell>
              <TableCell>Đơn vị</TableCell>
              <TableCell>Danh hiệu thi đua khen thưởng</TableCell>
              <TableCell>Thành tích</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map(({ name, dept, awards, note }, index) => (
              <TableRow hover key={index + 1}>
                <TableCell align="center">{index + 1}</TableCell>
                <TableCell>{name}</TableCell>
                <TableCell>{dept}</TableCell>
                <TableCell>
                  {awards && awards.length > 0 ? awards.join(", ") : ""}
                </TableCell>
                <TableCell>{note}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </div>
  );

  const year = new Date().getFullYear();

  const theme = useTheme();
  const { primary } = theme.palette;

  return (
    <div>
      <div style={{ color: primary.dark, backgroundColor: primary[50] }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" noWrap>
              {items.length === 0
                ? "Kết quả thi đua khen thưởng năm học "
                    .concat(year - 1)
                    .concat("-")
                    .concat(year)
                : "Kết quả từ file"}
            </Typography>
          </Grid>
          <Grid item xs={12} md={2}>
            <Button component="label" color="primary" startIcon={<Publish />}>
              Nhập từ file
              <input
                type="file"
                accept=".xlsx"
                hidden
                onChange={(e) => {
                  const file = e.target.files[0];
                  readExcel(file);
                }}
              />
            </Button>
          </Grid>
          {errorReadingFile && (
            <Grid item xs={12} md={2}>
              <Button
                onClick={() => handleError()}
                color="secondary"
                startIcon={<Error />}
              >
                Lỗi xử lý file
              </Button>
            </Grid>
          )}
          {items.length > 0 && (
            <Grid item xs={12} md={2}>
              <Button
                onClick={() => save(year)}
                color="primary"
                startIcon={<Save />}
              >
                Ghi nhận kết quả
              </Button>
              <Button
                onClick={() => reject()}
                color="secondary"
                startIcon={<Undo />}
              >
                Bỏ
              </Button>
            </Grid>
          )}
        </Grid>
      </div>

      {items.length > 0
        ? TdktTable(items)
        : !isLoaded(staffs)
        ? "Đang tải..."
        : isEmpty(staffs)
        ? "Danh sách rỗng"
        : TdktTable(displayResults(staffs, year))}
    </div>
  );
}
